.DefaultTextCta {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .header {
        font-family: 'Cabin', sans-serif;
        height: 60%;
        width: 100%;
        font-weight: 700;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        flex-direction: column;
        

        .social-logo-container {
            height: 30%;

            .social-cta-logo {
                max-height: 100%;
                object-fit: contain;
            }
        }
        
        .cta-container {
            position: relative;
            flex-grow: 1;
            margin-bottom: 18px;
        }

        .CtaText {
            display: flex;
            align-items: center;
            height: 100%;
            line-height: 100%;
            justify-content: center;
        }
    }

    .footer {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;

       .top{
            display: flex;
            flex-direction: column;
            align-content: center;
            font-size: 32px;
            text-align: center;
            font-weight: lighter;
            margin: 24px 0;
            
            .icon {
                font-size: 41px;
            }
            .vBar{
                width: 4px;
                background-color: var(--text-color);
                display: block;
                margin: 10px;
                height : calc(100% - 20px);
            }
        }
    
        .bar {
            height: 4px;
            width: 100%;
            border-radius: 3px;
            background-color: var(--text-color);
            display: block;
            margin: 15px 0;
        }

        .bottom{
            display: flex;
            flex-direction: column;
            height: 100%;
            text-align: center;

            .CtaHashtags {
                height: 100%;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                display: flex;
                align-items: center;
                justify-content: center;
                .hashtags {
                    display: block;
                    text-transform: uppercase;
                    &.long_cta {
                        div {
                            display: inline-block;
                            text-transform: uppercase;
                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

        }
    }
}

.grid,
.default {
    margin : 10px 30px 0 30px;
    width: calc(100% - 120px);
    height: 87%;
    
    .footer {
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 30%;
        width: 100%;
        .top {
            .icon {
                margin: 0 5px;
            }
        }

        .bar {
            width: 4px;
            height: 100%;
            margin: 0 20px;
        }

        .bottom{
            display: flex;
            flex-direction: column;
            height: 100%;
            text-align: center;

            .CtaHashtags {
                height: 100%;
                margin: 0;
            } 
        }
    }
}

.zoom {
    .footer {

        .top{
            font-size: 48px;

            .icon {
                margin-top: 10px;
                font-size: 48px;
            }
        }
    }
}

.vertical {
    .grid {
        .header {
            height: 40%;
        }
        .footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 38px;

            .bottom {
                width: 56%;
                border-left: 3px solid white;
                padding-left: 28px;
            }

            .top {
                > span {
                    padding-right: 20px;
                }
                .icon { 
                    font-size: 32px;
                }
            }
        }
    }   
}

.longCta {
    &.grid {
        .header {
            height: 47%;
        }
        .footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 38px;

            .bottom {
                width: 56%;
                border-left: 3px solid white;
                padding-left: 28px;
            }

            .top {
                > span {
                    padding-right: 20px;
                }
                .icon { 
                    font-size: 32px;
                }
            }
        }
    }   
}