.SidebarLayout {
    display: flex;
    width: 100%;
    height: 100%;

    .left-col {
        width: 100%;
        max-width: 360px;
    }

    .right-col {
        display: flex;
        flex-direction: column;
        width: 100%;

        .main-img-container {

            width: calc(1920px - 360px);
            height: calc((1920px - 360px) * (1080 / 1920));

            .main-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .footer-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            padding-bottom: 60px;
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}